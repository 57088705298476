<template>
  <FormContainer @submitForm="submit">
    <FeedbackAlert v-if="feedback === 'error'">Errore nel salvataggio dei dati! Riprovare</FeedbackAlert>
    <FeedbackAlert v-else-if="feedback === 'email'">L'email indicata risulta associata ad un altro utente</FeedbackAlert>

    <div class="row">
      <FormInputTextWidget
        class="col-12 col-md-6"
        label="Nome"
        v-model="user.firstName"
        :errorMsg="errorHash.firstName"
        @input="removeFieldError('firstName')"
      />

      <FormInputTextWidget
        class="col-12 col-md-6"
        label="Cognome"
        v-model="user.lastName"
        :errorMsg="errorHash.lastName"
        @input="removeFieldError('lastName')"
      />

      <FormInputTextWidget
        class="col-12 col-md-6"
        label="Email"
        v-model="user.email"
        type="email"
        :disabled="edit"
        :errorMsg="errorHash.email"
        @input="removeFieldError('email')"
      />
    </div>
    <div class="row">
      <FormInputTextWidget
        class="col-12 col-md-6"
        label="Password"
        v-model="user.password"
        type="password"
        :errorMsg="errorHash.password"
        @input="removeFieldError('password')"
      />

      <FormInputTextWidget
        class="col-12 col-md-6"
        label="Ripeti password"
        v-model="confirmPassword"
        type="password"
        :errorMsg="errorHash.confirmPassword"
        @input="removeFieldError('confirmPassword')"
      />
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <button class="btn btn-primary px-5">Salva</button>
      </div>
    </div>
  </FormContainer>
</template>

<script>

import { emailIsValid } from '@/helpers/validation.js';

import validateMixin from '@/libs/Form/mixins/validate.js';
import modalsManagerMixin from '@/libs/Modals/mixins/manager.js';
import rolesMixin from '@/mixins/roles.js';

import FormContainer from '@/views/components/Form/FormContainer.vue';
import FormInputTextWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextWidget.vue';

export default {
  mixins: [validateMixin, modalsManagerMixin, rolesMixin],
  components: {
    FormContainer,
    FormInputTextWidget,
    FeedbackAlert: () => import('@/libs/Feedback/components/Alert.vue'),
  },
  props: {
    user: {
      type: Object,
      default: () => ({
        firstName: null,
        lastName: null,
        email: null,
        password: null,
      }),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: String,
      default: null,
    },
  },
  data: () => ({ confirmPassword: null }),
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.$emit('update:feedback', null);
    },
    isValid () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.user.firstName) {
        this.addError('Inserisci il nome', 'firstName');
      }
      if (!this.user.lastName) {
        this.addError('Inserisci il cognome', 'lastName');
      }

      if (!this.user.email) {
        this.addError('Inserire l\'email.', 'email');
      } else if (!emailIsValid(this.user.email)) {
        this.addError('Email non valida.', 'email');
      }

      if (!this.user.password) {
        this.addError('Inserisci la password', 'password');
      } else if (this.user.password.length < 8 || !this.user.password.match(/\d|[^\w\s]/)) {
        this.addError('La password deve avere almeno 8 caratteri, compreso un numero o un simbolo.', 'password');
      } else if (!this.confirmPassword) {
        this.addError('Inserisci la password di conferma', 'confirmPassword');
      } else if (this.user.password !== this.confirmPassword) {
        this.addError('La password e la password di conferma devono essere uguali', 'confirmPassword');
      }

      return !this.hasErrors;
    },
    submit () {
      if (!this.isValid()) {
        return;
      }

      const userToReturn = { ...this.user };

      this.$emit('submitForm', userToReturn);
    },
  },
};

</script>

<template>
  <main class="page-signup d-flex align-items-start justify-content-center">
    <AwlModalCard class="signup-card" title="Registrazione">
      <template v-if="!signedUp">
        <div v-if="socialLoginEnabled" class="pb-3">
          <p>Iscriviti con i social</p>
          <button class="btn btn-fb mr-5" @click.prevent="signupWithProvider('facebook')"><i class="fab fa-facebook-f"></i> Facebook</button>
          <button class="btn btn-google" @click.prevent="signupWithProvider('google')"><i class="fab fa-google"></i> Google</button>
        </div>
        <div v-if="socialLoginEnabled" class="row mt-3 mb-0">
          <div class="col-auto">oppure</div>
          <div class="col"><hr></div>
        </div>
        <SignUpForm btn-label="Registrati" :feedback.sync="feedback" @submitForm="signup" />
        <div class="row mt-3 mb-3">
          <div class="col"><hr></div>
        </div>
        <p>Hai un account? <router-link :to="{ name: 'login' }">Accedi</router-link></p>
      </template>
      <FeedbackAlert v-else status="success">
        Registrazione effettuata con successo, ti è stata inviata una e-mail per la conferma
      </FeedbackAlert>
    </AwlModalCard>
  </main>
</template>

<script>

import AwlModalCard from '@/views/components/Cards/AwlModalCard.vue';
import SignUpForm from '@/views/components/Form/SignUpForm.vue';

export default {
  components: {
    AwlModalCard,
    SignUpForm,
    FeedbackAlert: () => import('@/libs/Feedback/components/Alert.vue'),
  },
  data: () => ({
    socialLoginEnabled: false,
    feedback: null,
    signedUp: false,
  }),
  methods: {
    signup (user) {
      this.$api.signup(user)
        .then(() => {
          this.signedUp = true;
          this.feedback = 'success';
          this.$router.replace({ name: 'sign-up.success' });
        })
        .catch(err => {
          if (err?.response?.status === 406) {
            this.feedback = 'email';
          } else {
            this.feedback = 'error';
          }
        })
      ;
    },
    signupWithProvider (providerName) {
      this.$oauth2
        .loginWithProvider(providerName)
        .then(() => {
          this.$router.push({ name: 'home' });
        })
        .catch(err => {
          this.$log.error(err);
        })
      ;
    },
  },
  mounted () {
    if (this.$route.name === 'sign-up.success') {
      this.signedUp = true;
    }
  },
};

</script>

<style lang="scss">

.awl-card.signup-card {
  max-width: 100% !important;

  @include media-breakpoint-up("md") {
    margin: 0 3rem;
  }
}

</style>

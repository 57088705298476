export default {
  data: () => ({
    modals: {},
  }),
  computed: {
    $modals () {
      return this.modals;
    },
  },
  methods: {
    $openModal (name) {
      this.$set(this.modals, name, true);
    },
    $closeModal (name) {
      this.$set(this.modals, name, false);
    },
  },
};
